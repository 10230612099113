<template>
  <div
    class="project--detail-attachment"
    v-show="
      project.youtube_url ||
      (galleries && galleries.length > 0) ||
      (project.videos && project.videos.length > 0)
    "
  >
    <!-- youtube_url is not used anymore, now using videos -->
    <!--    <div-->
    <!--      class="attachment"-->
    <!--      v-if="project.youtube_url"-->
    <!--      @click="$modal.show('youtube-modal', { youtubeUrl: project.youtube_url })"-->
    <!--    >-->
    <!--      <img-->
    <!--        class="attachment&#45;&#45;bg"-->
    <!--        :src="`//img.youtube.com/vi/${getYoutubeId(project.youtube_url)}/0.jpg`"-->
    <!--      />-->
    <!--      <div class="attachment&#45;&#45;bg-overlay"></div>-->
    <!--      <div class="attachment&#45;&#45;content">Video</div>-->
    <!--    </div>-->
    <div v-if="project.videos && project.videos.length > 0" class="d-flex flex-wrap">
      <div
        class="attachment"
        v-for="(video, index) in project.videos"
        :key="'video-' + index"
        @click="$modal.show('youtube-modal', { youtubeUrl: video.url })"
      >
        <img
          class="attachment--bg"
          :src="`//img.youtube.com/vi/${getYoutubeId(video.url)}/0.jpg`"
        />
        <div class="attachment--bg-overlay"></div>
        <div class="attachment--content">Video</div>
      </div>
    </div>
    <div
      class="attachment"
      v-if="galleries && galleries.length > 0 && galleries[0]"
      @click="clickImage(0)"
    >
      <picture>
        <source :srcset="galleries[0].url" type="image/webp" />
        <source :srcset="getFallbackImage(galleries[0].url)" type="image/jpeg" />
        <img
          :src="getFallbackImage(galleries[0].url)"
          class="attachment--bg"
          :alt="`Denah ${project.project_title} - BeliRumah`"
        />
      </picture>
      <div class="attachment--bg-overlay"></div>
      <div class="attachment--content">Floor Plans</div>
    </div>
    <!--    <a-->
    <!--      class="attachment"-->
    <!--      v-if="project.brochure_url"-->
    <!--      :href="project.brochure_url"-->
    <!--      target="_blank"-->
    <!--      style="text-decoration: none;"-->
    <!--    >-->
    <!--      <div class="attachment&#45;&#45;bg-icon">-->
    <!--        <v-icon>mdi-file-document-outline</v-icon>-->
    <!--      </div>-->
    <!--      <div class="attachment&#45;&#45;bg-overlay"></div>-->
    <!--      <div class="attachment&#45;&#45;content">{{ $t('general.downloadBrochure') }}</div>-->
    <!--    </a>-->
    <client-only>
      <vue-gallery-slideshow
        :images="galleries"
        :index="imageIndex"
        @close="clickImage(null)"
      ></vue-gallery-slideshow>
    </client-only>
  </div>
</template>

<script>
import { getYoutubeId } from '@/utils/helpers';

const VueGallerySlideshow = () => import('vue-gallery-slideshow');
import { mapState, mapGetters } from 'vuex';
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'project-detail-attachment',
  mixins: [HelperMixin],
  components: {
    VueGallerySlideshow,
  },
  data() {
    return {};
  },
  props: ['project'],
  methods: {
    getYoutubeId,
    clickImage(index) {
      this.$store.commit('v2/project/set_floor_plan_index', index);
    },
  },
  computed: {
    ...mapState({
      imageIndex: (state) => state.v2.project.floorPlanIndex,
      baseUrl: (state) => state.global.baseUrl,
      isLoading: (state) => state.global.isLoading,
    }),
    ...mapGetters({
      galleries: 'v2/project/floorPlanGalleries',
    }),
  },
  async mounted() {},
  beforeDestroy() {
    this.$store.commit('v2/project/set_floor_plan_index', null);
  },
};
</script>
