<template>
  <section class="project--detail-calculator">
    <div class="calculator--content">
      <form class="left--part" @submit.prevent="submitCalculation">
        <div class="title--text">
          {{ $t('general.mortgageCalculator') }}
        </div>
        <div class="row">
          <div
            class="form--group col-6 d-block"
            :class="{ 'has-error': validation.hasError('purchasePrice') }"
          >
            <label for="purchasePrice">{{ $t('mortgage.purchasePrice') }}</label>
            <cleave
              class="basic--input mt-2"
              id="purchasePrice"
              name="purchasePrice"
              :placeholder="$t('mortgage.purchasePrice')"
              v-model="purchasePrice"
              :options="cleaveOptionsPrice"
            ></cleave>
            <div class="val-error mt-2" v-if="validation.hasError('purchasePrice')">
              {{ validation.firstError('purchasePrice') }}
            </div>
          </div>
          <div
            class="form--group col-6 d-block"
            :class="{ 'has-error': validation.hasError('downPayment') }"
          >
            <label for="downPayment">{{ $t('mortgage.downPayment') }}</label>
            <cleave
              class="basic--input mt-2"
              id="downPayment"
              name="downPayment"
              :placeholder="$t('mortgage.downPayment')"
              v-model="downPayment"
              :options="cleaveOptionsPrice"
            />
            <div class="val-error mt-2" v-if="validation.hasError('downPayment')">
              {{ validation.firstError('downPayment') }}
            </div>
          </div>
          <div
            class="form--group col-6 d-block"
            :class="{ 'has-error': validation.hasError('interestRate') }"
          >
            <label for="interestRate">{{ $t('mortgage.interestRate') }}</label>
            <cleave
              class="basic--input mt-2"
              id="interestRate"
              name="interestRate"
              type="text"
              :placeholder="$t('mortgage.interestRate')"
              v-model="interestRate"
              :options="cleaveOptionPercentage"
            />
            <div class="val-error mt-2" v-if="validation.hasError('interestRate')">
              {{ validation.firstError('interestRate') }}
            </div>
          </div>
          <div
            class="form--group col-6 d-block"
            :class="{ 'has-error': validation.hasError('tenureInYear') }"
          >
            <label for="tenureInYear">{{ $t('mortgage.loanTenure') }}</label>
            <cleave
              class="basic--input mt-2"
              id="tenureInYear"
              name="tenureInYear"
              type="text"
              :placeholder="$t('mortgage.loanTenure')"
              v-model="tenureInYear"
              :options="cleaveOptionPercentage"
            />
            <div class="val-error mt-2" v-if="validation.hasError('tenureInYear')">
              {{ validation.firstError('tenureInYear') }}
            </div>
          </div>
        </div>
      </form>
      <div class="right--part">
        <div class="mb-4">
          <div class="title--text px-2 mb-2">{{ $t('mortgage.result') }}</div>
          <div class="monthly--payment px-2">
            <div>{{ $t('mortgage.monthlyAmount') }}:</div>
            <div class="monthly--payment-total">{{ $n(monthlyPayment, 'currency', 'id-ID') }}</div>
          </div>
        </div>
        <div class="action--wrapper">
          <a :href="mortgageLink" class="btn btn-primary mb-4">
            {{ $t('mortgage.enquireMortgage') }}
          </a>
          <a :href="calculatorLink" class="text--button">
            {{ $t('mortgage.studyMortgage') }}
          </a>
          <!--          <button class="btn btn-primary" @click="openWA">-->
          <!--            {{ $t('mortgage.enquireMortgage') }}-->
          <!--          </button>-->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Validator } from 'simple-vue-validator';
const Cleave = () => import('vue-cleave-component');
import LodashMixin from '@/mixins/lodash';
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'mortgage-calculator',
  mixins: [LodashMixin, HelperMixin],
  props: ['project', 'projectUnitType'],
  components: {
    Cleave,
  },
  computed: {
    ...mapState({
      baseUrl: (state) => state.global.baseUrl,
      ltv: (state) => state.v2.mortgage.ltv,
      defaultDP: (state) => state.v2.mortgage.defaultDP,
      listingTypeEnum: (state) => state.v2.masters.listingTypeEnum,
    }),
    purchasePrice: {
      get() {
        return this.purchasePriceValue;
      },
      set(val) {
        this.purchasePriceValue = val
          ? typeof val == 'string'
            ? parseFloat(val.replace('Rp', '').trim())
            : val
          : 0;
        this.submitCalculation();
      },
    },
    downPayment: {
      get() {
        return this.downPaymentValue;
      },
      set(val) {
        this.downPaymentValue = val
          ? typeof val == 'string'
            ? parseFloat(val.replace('Rp', '').trim())
            : val
          : 0;
        this.submitCalculation();
      },
    },
    mortgageLink() {
      let url = '/kpr';
      if (this.project) {
        if (this.project.listing_type === this.listingTypeEnum.PRIMARY) {
          url += `?project_uuid=${this.project.uuid}`;
        } else {
          url += `?listing_uuid=${this.project.uuid}`;
        }
      }
      if (this.projectUnitType) {
        url += `&project_unit_type_uuid=${this.projectUnitType.uuid}`;
      }
      if (this.tenureInYear) {
        url += `&tenure_in_year=${this.tenureInYear}`;
      }
      if (this.downPayment) {
        url += `&down_payment=${this.downPayment}`;
      }
      return url;
    },
    calculatorLink() {
      let url = '/kalkulator?param=true';
      if (this.project) {
        if (this.project.listing_type === this.listingTypeEnum.PRIMARY) {
          url += `&project_uuid=${this.project.uuid}`;
        } else {
          url += `&listing_uuid=${this.project.uuid}`;
        }
      }
      if (this.projectUnitType) {
        url += `&project_unit_type_uuid=${this.projectUnitType.uuid}`;
      }
      if (this.purchasePriceValue) {
        url += `&purchase_price=${this.purchasePriceValue}`;
      }
      if (this.tenureInYear) {
        url += `&tenure_in_year=${this.tenureInYear}`;
      }
      if (this.downPayment) {
        url += `&down_payment=${this.downPayment}`;
      }
      return url;
    },
  },
  data: () => ({
    cleaveOptionsPrice: {
      numeral: true,
      numeralDecimalMark: ',',
      delimiter: '.',
      prefix: 'Rp ',
    },
    cleaveOptionPercentage: {
      prefix: '',
      numeral: true,
      numeralPositiveOnly: true,
      noImmediatePrefix: true,
      rawValueTrimPrefix: true,
      numeralDecimalMark: ',',
      delimiter: '.',
      numeralIntegerScale: 2,
      numeralDecimalScale: 2,
    },
    purchasePriceValue: 0,
    loanAmountValue: 0,
    downPaymentValue: 0,
    tenureInYear: 0,
    interestRate: 0,
    monthlyPayment: 0,
  }),
  methods: {
    async setupCalculator() {
      await this.$store.dispatch('v2/mortgage/getLTV', this.project);
      if (this.project) {
        if (this.project.listing_type === this.listingTypeEnum.PRIMARY) {
          this.purchasePrice = this.project.min_price_num;
        } else {
          this.purchasePrice = this.project.price;
        }
      } else {
        this.purchasePrice = 0;
      }
      this.downPayment = parseInt(this.purchasePrice * this.defaultDP);
      this.loanAmountValue = this.purchasePrice - this.downPayment;
      this.setupInterestRate();
      this.tenureInYear = 20;
      this.calculateMortgage();
    },
    setupInterestRate() {
      if (this.purchasePrice <= 185000000) {
        this.interestRate = 5;
      } else {
        this.interestRate = 12;
      }
    },
    calculateMortgage() {
      let monthlyInterestRate = this.interestRate / 100 / 12;
      let tenureInMonths = this.tenureInYear * 12;
      let totalInterestRate = Math.pow(1 + monthlyInterestRate, tenureInMonths);
      // console.log('qwe 1', monthlyInterestRate);
      // console.log('qwe 2', tenureInMonths);
      // console.log('qwe 3', totalInterestRate);
      // console.log('qwe 4', this.loanAmountValue);
      this.loanAmountValue = this.purchasePrice - this.downPayment;
      this.monthlyPayment =
        (this.loanAmountValue * totalInterestRate * monthlyInterestRate) / (totalInterestRate - 1);
    },
    async submitCalculation() {
      const isValid = await this.$validate();
      if (isValid) {
        this.calculateMortgage();
      }
    },
    openWA() {
      let waText = `Halo tim BeliRumah, saya tertarik untuk mengajukan KPR di listing ini: ${
        this.baseUrl + this.$route.fullPath
      } dengan detail sebagai berikut:%0aHarga Properti: ${this.$n(
        this.purchasePrice,
        'currency',
        'id-ID',
      )}%0aJumlah Pinjaman: ${this.$n(this.loanAmount, 'currency', 'id-ID')}%0aSuku Bunga: ${
        this.interestRate
      }%%0aJangka Waktu Pinjaman: ${
        this.tenureInYear
      } tahun%0aMohon berikan saya info lebih lanjut.`;
      window.open(`https://wa.me/${this.$t('general.mortgageNumber')}?text=${waText}`, '_blank');
    },
  },
  async mounted() {
    let self = this;
    setTimeout(async () => {
      await self.setupCalculator();
    }, 100); // HARUS DITUNGGU, kalau tidak akan buggy behaviour khusus untuk mobile responsive
  },
  validators: {
    purchasePrice(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.calculator.purchasePrice.required'))
        .custom(() => {
          if (this.purchasePriceValue <= 0) {
            return `Harga Properti harus valid.`;
          }
        });
    },
    downPayment(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.calculator.downPayment.required'))
        .custom(() => {
          if (this.downPaymentValue > this.purchasePriceValue) {
            return `Jumlah DP tidak bisa lebih dari Harga Properti.`;
          }
        });
      // .custom(() => {
      //   if (this.downPaymentValue > this.purchasePriceValue * this.ltv) {
      //     return `Maksimal Jumlah Pinjaman adalah ${this.ltv * 100}% dari Harga Properti.`;
      //   }
      // });
    },
    interestRate(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.calculator.interestRate.required'))
        .float(this.$i18n.t('errors.calculator.interestRate.number'))
        .custom(() => {
          if (this.interestRate <= 0) {
            return `Suku Bunga harus valid.`;
          }
        });
    },
    tenureInYear(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.calculator.tenureInYear.required'))
        .float(this.$i18n.t('errors.calculator.tenureInYear.number'))
        .custom(() => {
          if (this.tenureInYear <= 0) {
            return `Jangka Waktu Pinjaman harus valid.`;
          }
        });
    },
  },
  watch: {
    interestRate() {
      this.submitCalculation();
    },
    tenureInYear() {
      this.submitCalculation();
    },
  },
};
</script>
